import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading } from "../misc/Headings.js";

const Container = tw.div`relative`;

const HeadingTitle = tw(SectionHeading)`text-purple-800`;

const SingleColumn = tw.div`mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-96 bg-contain bg-no-repeat bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-purple-700 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-indigo-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-indigo-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-indigo-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-indigo-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-indigo-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: "/gintex.png",
      subtitle: "Spinning",
      title: "Gintex",
      description: `Gintex is a fibre
        conditioner
        manufactured by M/s
        Cotton Conditioners
        Inc., U.S.A.
        Using Gintex, you can
        prevent damage to the
        wax coating present on
        the cotton fibre. This
        leads to a substantial
        quantitative and
        qualitative
        improvement in the
        spinning of cotton.`,
      url: "/Brochure Gintex.pdf",
    },
    {
      imageSrc:
        "/pump.jpg",
      subtitle: "Spinning",
      title: "Dispensing pumps for blow room fluids",
      description:
        "Gintex is drawn by gravity and goes through a metering device to the nozzles. These nozzles spray a very small quantity of Gintex on to the cotton. The rate of application is very low, less than 2 litres per hour, in a blow-room with 1000 kg/hour throughput. <br /> We use compressed air of about 2-3 bar pressure to make a spray of Gintex. The device has been in operation on various bale pluckers, lattice feeders and circular bale pluckers.",
    },
    {
      imageSrc: "/lbt.jpg",
      subtitle: "Weaving",
      title: "Warp Beam Trolley",
      description: `1. Fork length : Up to 280 cm.<br />
        2. Roll / Flange diameter up to 1200mm<br />
        3. Load up to 1600 kg<br />
        4. Battery operated drive and hydraulics<br />
        5. Battery 4800WH LifePO4`,
    },

    {
      imageSrc: "/kbt.jpg",
      subtitle: "Weaving",
      title: "Knotting Beam Trolley",
      description: `1. Beam length : up to 280 cm<br />
        2. Beam Flange Diameter : up to 800 to 1100 mm<br />
        3. Beam weight : up to 1600 Kg<br />
        4. Transport speed : Max: 3.5 KM per hour. Walking
        speed.<br /> 5. Transport width : 1300 mm<br />
        6. Beam alley recommended : 1600 mm<br />
        7. Drive : Battery operated.`,
    },

    {
      imageSrc: "/bbt.jpeg",
      subtitle: "Weaving",
      title: "Big Batch Trolley",
      description: `1. Batch / Roll Diameter : up to 1250mm<br />
        2. Batch lengths up to 280 cm<br />
        3. Batch weight up to 1200 Kgs<br />
        4. Cradle type or arm type , based on the core used. 5. Transport width : 1550 mm<br />
        6. Alley width recommended 1800 mm<br />
        7. Battery 4800WH LifePO4`,
    },
    {
      imageSrc: "/drive-conversion.jpg",
      subtitle: "Weaving",
      title: "Drive Conversion of Old Trolleys",
      description: `1. We undertake conversion work for old trolleys<br />
        2. We can provide new controllers and control electronics for both DC and AC trolleys<br />
        3. We use your existing motors and hydraulics while replacing all the other components. If necessary we also provide new AC motors and DC hydraulic pumps.<br />
        4. You can benefit from shorter down times, easy availablity of spares and reduced maintenence times for your old trolleys<br />
        5. We have successfully converted over 30 such trolleys and have a good experience in it.`,
    },

    {
      imageSrc: "/4.png",
      subtitle: "Others",
      title: "Tuggers / Pullers for heavy loads",
      description: `1. To pull A-Frames or other wheel mounted loads.<br /> 2. Maximum pulling weight 4 Tons<br />
        3. Drive speed 3.5 KM per hour<br />
        4. Battery 4800WH LifePO4`,
    },
    {
      imageSrc: "",
      subtitle: "Others",
      title: "Automated guided vehicles",
      description: `Work in progress. Stay tuned for updates.`,
    },
  ];

  return (
    <Container id="products">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Our Products</HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description
                  dangerouslySetInnerHTML={{ __html: card.description }}
                ></Description>
                {!!card.url && <Link href={card.url}>Download Brochure</Link>}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
